//
// Provides API to count visits to specific entities.
// Stores data to local storage.
//
export class TasteTracker {
  constructor(private key: string) {}

  trackArtwork(id: number) {
    return this._track(id, 'artwork');
  }

  trackArtworkGroup(id: number) {
    return this._track(id, 'artwork-group');
  }

  artwork(id?: number) {
    if (id) {
      return this._get(id, 'artwork');
    } else {
      return this._typeData('artwork');
    }
  }

  artworkGroup(id?: number) {
    if (id) {
      return this._get(id, 'artwork-group');
    } else {
      return this._typeData('artwork-group');
    }
  }

  private _storageKey() {
    return `taste-${this.key}`;
  }

  private _data() {
    const data = localStorage.getItem(this._storageKey());
    if (!data) {
      return {};
    }
    return JSON.parse(data);
  }

  private _track(id: number, type: string) {
    const data = this._data();
    if (!data[type]) {
      data[type] = {};
    }
    data[type][id] = data[type][id] ? data[type][id] + 1 : 1;
    localStorage.setItem(this._storageKey(), JSON.stringify(data));
    return data[type][id];
  }

  private _get(id: number, type: string) {
    const data = this._data();
    if (!data[type]) {
      return 0;
    } else {
      return data[type][id] || 0;
    }
  }

  private _typeData(type: string) {
    const data = this._data();
    return data[type] ? data[type] : {};
  }
}
