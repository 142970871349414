/**
 * This file contains various static constants, polyfills, anything
 * that makes modern web development possible.
 */

//
// Configure Bugsnag
//
import bugsnag from '@bugsnag/js';

const bugsnagClient = bugsnag({
  apiKey: '8e51fd02ee17fb23de66b57b58f66bb0',
  notifyReleaseStages: ['production', 'staging'],
  releaseStage: process.env.NODE_ENV,
});

window.bugsnag = bugsnagClient;

//
// Various polyfills
//
import 'svgxuse';
import 'utils/detect-touch';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import FastClick from 'fastclick';
// @ts-ignore
FastClick.attach(document.body);

//
// Expose jQuery
//
import $ from 'jquery';
import 'jquery-migrate';
require('at.js/dist/js/jquery.atwho');

// @ts-ignore
$.migrateMute = true;

$.Deferred.exceptionHook = function (error: any) {
  window.bugsnag &&
    window.bugsnag.notify(new Error('jQuery.Deferred exception'), {
      metaData: {
        message: error && error.message ? error.message : undefined,
      },
    });
};

// @ts-ignore
(window as any).$ = $;
(window as any).jQuery = $;

/**
 * Backbone depends on underscore. It should work with lodash as well.
 * Let's expose it for use in Backbone.
 */
import _ from 'lodash';
(window as any)._ = _;

/**
 * Expose CurrentUser wrapper
 */
import { CurrentUser } from 'services/CurrentUser';
window.CurrentUser = CurrentUser;

/**
 * Expose routes
 */
import * as Routes from 'routes';
window.Routes = Routes;
