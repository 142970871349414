/**
 * Detects user touch. Adds body.user-can-touch
 */

const onFirstTouch = () => {
  document.body.classList.add('user-can-touch');
  // we only need to know once that a human touched the screen, so we can stop listening now
  window.removeEventListener('touchstart', onFirstTouch, false);
};

window.addEventListener('touchstart', onFirstTouch, false);
