import * as Types from 'types';

import { TasteTracker } from 'services/TasteTracker';

class Storage {
  constructor(private id: string) {}

  set(key: string, value: any) {
    return localStorage.setItem(this.prefix(key), JSON.stringify(value));
  }

  get(key: string) {
    const value = localStorage.getItem(this.prefix(key));
    return value ? JSON.parse(value) : null;
  }

  private prefix(key: string) {
    return `storage-${this.id}-${key}`;
  }
}

export class CurrentUser {
  static GUEST_USER_ID = 'guest';
  static DEFAULT_AVATAR_URL = '';

  private id = CurrentUser.GUEST_USER_ID;
  taste: TasteTracker;
  storage: {
    session: Storage;
    signed: Storage;
  };

  constructor(private model?: Types.LoggedInUser) {
    if (model) {
      this.id = String(model.id);
    }
    this.taste = new TasteTracker(this.id);
    this.storage = {
      session: new Storage('session'),
      signed: new Storage(this.id),
    };
  }

  get<T extends keyof Types.LoggedInUser>(key: T) {
    if (key === 'id') {
      return this.id;
    }

    if (this.model) {
      return this.model[key];
    } else {
      return undefined;
    }
  }

  isGuest() {
    return this.id === CurrentUser.GUEST_USER_ID;
  }
}
